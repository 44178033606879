import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, RequestUpdateModel, UserModel } from 'src/app/modules/auth';
import { EventsService } from 'src/app/modules/events/events.service';
import { RDSEvent, Status } from 'src/app/modules/events/model/rds-events.model';
import { environment } from 'src/environments/environment';
import { AnimalDataModel, ChildModel, KeeperModel, PartnerDataModel } from '../models/people.model';
import { MinutaModel } from 'src/app/modules/auth/_models/minuta.model';

// const API_MSG_URL = `${environment.apiUrl}/willkeeper/animal`;
const API_BASE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private eventService: EventsService) { }

  updateTerms(msg: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_BASE_URL}/users/term-use`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          const userSessionData: UserModel = JSON.parse(window.sessionStorage.getItem('userData'));
          this.saveLogin({
            ...userSessionData, ...msg,
            setUser: function (user: any): void {
              throw new Error('Function not implemented.');
            },
            setAuth: function (auth: any): void {
              throw new Error('Function not implemented.');
            }
          });
          return ob.data;
        }));
  }
  updateMe(msg: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_BASE_URL}/users/me`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          const userSessionData: UserModel = JSON.parse(window.sessionStorage.getItem('userData'));
          if (window.sessionStorage.getItem('userData')) {

            this.saveLogin({
              ...userSessionData, ...msg,
              setUser: function (user: any): void {
                throw new Error('Function not implemented.');
              },
              setAuth: function (auth: any): void {
                throw new Error('Function not implemented.');
              }
            });
          }
          return ob.data;
        }));
  }

  updateMeDocument(formData: any): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_BASE_URL}/users/me/upload-document`, formData,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  environment(): string {
    if (window.location.pathname.includes('particular')) return 'PARTICULAR'
    if (window.location.pathname.includes('vital')) return 'VITAL'
    if (window.location.pathname.includes('inventory')) return 'INVENTORY'
  }

  getMe(updateLocal?: boolean): Observable<UserModel> {
    const userSessionData: UserModel = JSON.parse(window.sessionStorage.getItem('userData'));
    if (!updateLocal && window.sessionStorage.getItem('userData')) {
      return new Observable((resolve) => {
        setTimeout(() => {
          resolve.next(userSessionData)
          resolve.complete()
        }, 1000);
      });
    }

    return this.http.get<UserModel>(`${API_BASE_URL}/users/me`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          const myUser: UserModel = ob.data
          myUser.stepFour = ob.data.requestUpdate
          myUser.requestUpdate = !!ob.data.requestUpdate?.find(el => {
            return el.active && el.productType === this.environment()
          })
          this.saveLogin(myUser);
          return ob.data;
        }));
  }
  getMinutaVersions(product: string): Observable<RequestUpdateModel> {
    return this.http.get<UserModel>(`${API_BASE_URL}/users/me`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          const temp = ob.data.requestUpdate?.filter(el => el.productType === product)
          return temp?.length ? temp[temp.length - 1] : null;
        }));
  }

  updateChild(id: string, msg: ChildModel): Observable<ChildModel> {
    return this.http.post<ChildModel>(`${API_BASE_URL}/children/${id}`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  saveChild(msg: ChildModel): Observable<ChildModel> {
    return this.http.post<ChildModel>(`${API_BASE_URL}/children`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getAllChildren(): Observable<ChildModel[]> {
    return this.http.get<ChildModel[]>(`${API_BASE_URL}/children`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getChildren(id: string): Observable<ChildModel> {
    return this.http.get<ChildModel>(`${API_BASE_URL}/children/${id}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  updateKeeper(id: string, msg: KeeperModel): Observable<KeeperModel> {
    return this.http.post<KeeperModel>(`${API_BASE_URL}/keepers/${id}`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  saveKeeper(msg: KeeperModel): Observable<KeeperModel> {
    return this.http.post<KeeperModel>(`${API_BASE_URL}/keepers`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getAllKeepers(): Observable<KeeperModel[]> {
    return this.http.get<KeeperModel[]>(`${API_BASE_URL}/keepers`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getKeeper(id: string): Observable<KeeperModel> {
    return this.http.get<KeeperModel>(`${API_BASE_URL}/keepers/${id}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  updateAnimal(id: string, msg: AnimalDataModel): Observable<AnimalDataModel> {
    return this.http.post<AnimalDataModel>(`${API_BASE_URL}/animals/${id}`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  saveAnimal(msg: AnimalDataModel): Observable<AnimalDataModel> {
    return this.http.post<AnimalDataModel>(`${API_BASE_URL}/animals`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getAllAnimals(): Observable<AnimalDataModel[]> {
    return this.http.get<AnimalDataModel[]>(`${API_BASE_URL}/animals`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getAnimal(id: string): Observable<AnimalDataModel> {
    return this.http.get<AnimalDataModel>(`${API_BASE_URL}/animals/${id}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  updatePartner(id: string, msg: PartnerDataModel): Observable<PartnerDataModel> {
    return this.http.post<PartnerDataModel>(`${API_BASE_URL}/partner/${id}`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  savePartner(msg: PartnerDataModel): Observable<PartnerDataModel> {
    return this.http.post<PartnerDataModel>(`${API_BASE_URL}/partner`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getAllPartner(): Observable<PartnerDataModel[]> {
    return this.http.get<PartnerDataModel[]>(`${API_BASE_URL}/partner`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getPartner(id: string): Observable<PartnerDataModel> {
    return this.http.get<PartnerDataModel>(`${API_BASE_URL}/partner/${id}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  getHeader(): HttpHeaders {
    const user = this.authService.getAuthFromLocalStorage();
    return new HttpHeaders({
      token: `${user.token}`,
      id: `${user.id}`,
    });
  }
  download(filename: string): Observable<any> {

    return this.http.post<any>(`${API_BASE_URL}/download`, { file: filename },
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  saveMinutaVersion(productType: "VITAL" | "PARTICULAR", msg: MinutaModel): Observable<MinutaModel> {
    return this.http.post<PartnerDataModel>(`${API_BASE_URL}/users/save-minuta-version/${productType}`, msg,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }



  getHistoryMinuta(productType: "VITAL" | "PARTICULAR", iduser: number): Observable<MinutaModel[] | null> {
    return this.http.get<PartnerDataModel>(`${API_BASE_URL}/users/history-minuta/${productType}/${iduser}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data?.sort((a: MinutaModel, b: MinutaModel) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          });
        }));
  }
  createRequestUpdate(productType: "VITAL" | "PARTICULAR" | string, iduser: number): Observable<any> {
    return this.http.get<PartnerDataModel>(`${API_BASE_URL}/users/create-request-update/${productType}/${iduser}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }
  completeRequestUpdate(productType: "VITAL" | "PARTICULAR", iduser: number): Observable<any> {
    return this.http.get<PartnerDataModel>(`${API_BASE_URL}/users/complete-request-update/${productType}/${iduser}`,
      { headers: this.getHeader() }).pipe(
        map((ob: any) => {
          return ob.data;
        }));
  }

  private saveLogin(obj: UserModel) {
    window.sessionStorage.setItem('name', obj.firstname);
    window.sessionStorage.setItem('email', obj.email);
    window.sessionStorage.setItem('userData', JSON.stringify(obj));
  }
}
